import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { BlockRounded, EditOutlined, Person4, PersonPin } from '@mui/icons-material';
import { COLORS } from '../../utils/colors';
import React, { Fragment } from 'react';
import { getUserInfoById } from '../../services/user.service';
import { ClientStatus } from '../../utils/clientStatus';
import { getStatusColors } from '../../pages/admin/client/utils';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { useAppSelector } from '../../store/store';
import { UserModel } from '../../store/user/userModel';
import { UserRolesEnum } from '../../utils/rolesEnum';
import SuspendReasonModal from '../Modals/SuspendReasonModal/SuspendReasonModal';
import { shouldShowButton } from '../../pages/admin/dashboard/dashboardAccessControl';
import {
  convertClientToProspective,
  markEmploymentIntakeComplete,
  toggleClientStatus,
} from '../../services/client.service';
import { clientActiveStatus, clientIntakeStatus } from '../../utils/clientActiveStatus';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import GenericDialog from '../Dialog/genericDialog/genericDialog';
import PendingIcon from '@mui/icons-material/Pending';
import CheckIcon from '@mui/icons-material/Check';
import { Palette } from '../../utils/palette';
import { useNavigate } from 'react-router-dom';

interface ClientActionBarProps {
  clientInfo: any;
  clientNavigator: any;
  setClientNavigator: Function;
  setShowNavigatorModal: Function;
  isClientView?: boolean;
}

export const ClientActionBar = (props: ClientActionBarProps) => {
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const navigate = useNavigate();
  const { clientInfo, clientNavigator, setClientNavigator, setShowNavigatorModal, isClientView } = props;
  const [caseManagerOfClient, setCaseManagerOfClient] = React.useState<any>();
  const [suspendModal, setSuspendModal] = React.useState<boolean>(false);
  const [approvalAlert, setApprovalAlert] = React.useState<boolean>(false);
  const [isSuspended, setIsSuspended] = React.useState<boolean>(clientInfo.status === ClientStatus.SUSPENDED || false);
  const [toggleStatusLoading, setToggleStatusLoading] = React.useState(false);
  const [intakeStatusLoading, setIntakeStatusLoading] = React.useState(false);
  const [convertProspectiveLoading, setConvertProspectiveLoading] = React.useState(false);

  const tooltipDesc =
    // eslint-disable-next-line max-len
    'Client marked as “inactive” have either not been able to be contacted after at least 3 attempts across 3 different days or are temporarily not interested in PINCC, but still open to receiving updates regarding the program. Clients in “inactive” status do not have to be contacted, but can be reached out to as instructed by PINCC leadership. Once the client expresses interest again, they are marked as “active” by the staff member who reestablishes contact. Inactive clients can continue to be moved through other statuses and/or be sent to employment.';

  const intakeStatus = clientInfo?.employmentIntakeComplete?.status
    ? clientInfo?.employmentIntakeComplete?.status === clientIntakeStatus.ON
      ? clientIntakeStatus.ON
      : clientIntakeStatus.OFF
    : clientInfo?.employmentIntakeComplete
    ? clientIntakeStatus.ON
    : null;
  const handleToggleStatus = async (nextView: string) => {
    try {
      setToggleStatusLoading(true);
      await toggleClientStatus(clientInfo?.id, nextView);
      setToggleStatusLoading(false);
    } catch (error) {
      //catch error
    }
  };

  const handleToggleIntakeStatus = async () => {
    try {
      let statusValue = intakeStatus
        ? intakeStatus === clientIntakeStatus.ON
          ? clientIntakeStatus.OFF
          : clientIntakeStatus.ON
        : clientIntakeStatus.ON;

      setIntakeStatusLoading(true);
      await markEmploymentIntakeComplete(clientInfo?.id, statusValue);
      setIntakeStatusLoading(false);
    } catch (error) {
      //catch error
    }
  };

  const getNavigatorOfClient = async (navId: string) => {
    const navigator: any = await getUserInfoById(navId);
    setClientNavigator(navigator);
  };

  const getCaseManagerOfClient = async (caseManagerId: string) => {
    const caseManager = await getUserInfoById(caseManagerId);
    setCaseManagerOfClient(caseManager);
  };

  const convertToProspective = async (clientId: string) => {
    try {
      setConvertProspectiveLoading(true);
      await convertClientToProspective(clientId);
      navigate('../prospective-client-info', {
        state: { clientId: clientId },
      });
      setConvertProspectiveLoading(false);
    } catch (error) {
      // handle error
    }
  };

  React.useEffect(() => {
    (async () => {
      if (clientInfo?.assignedTo) {
        await getNavigatorOfClient(clientInfo?.assignedTo);
      }
      if (clientInfo?.createdBy) {
        await getCaseManagerOfClient(clientInfo?.createdBy);
      }
    })();
    // eslint-disable-next-line
  }, [clientInfo]);

  return (
    <>
      <Grid container sx={styles.centerGrid} alignItems={'center'} gap={1}>
        <Grid item display={{ lg: 'flex', md: 'flex' }} alignItems={'center'}>
          <Typography variant='h5' fontWeight={500}>
            {clientInfo?.firstName + ' ' + clientInfo?.middleName + ' ' + clientInfo?.lastName}
          </Typography>

          {/* Client Staus Badge */}
          <ListItem sx={{ width: 'fit-content' }}>
            <Chip
              size='small'
              sx={{
                background: getStatusColors(isSuspended ? ClientStatus.SUSPENDED : clientInfo.status)?.bgColor,
                color: getStatusColors(isSuspended ? ClientStatus.SUSPENDED : clientInfo.status)?.textColor,
              }}
              label={
                <Typography variant='button' sx={styles.customChipLabel}>
                  {isSuspended ? ClientStatus.SUSPENDED : String(clientInfo?.status).replace('_', ' ')}
                </Typography>
              }
            />
            <Tooltip title={'Toggle Client Intake Complete Status'} arrow>
              <Box>
                <LoadingButton
                  size='small'
                  styles={{
                    background: intakeStatus === clientIntakeStatus.ON ? Palette.green_E4F3E5 : Palette.gray_EEEEEE,
                    color: intakeStatus === clientIntakeStatus.ON ? Palette.green_4CAF50 : Palette.black,
                    ml: 1,
                    px: 1,
                    borderRadius: 5,
                    textTransform: 'capitalize',
                  }}
                  loading={intakeStatusLoading}
                  onClick={handleToggleIntakeStatus}
                  label={intakeStatus === clientIntakeStatus.ON ? 'Intake Complete' : 'Intake Incomplete'}
                  startIcon={intakeStatus === clientIntakeStatus.ON ? <CheckIcon /> : <PendingIcon />}
                />
              </Box>
            </Tooltip>
            <Tooltip
              title={
                <Fragment>
                  <Typography sx={{ textAlign: 'justify', fontSize: 10 }}>{tooltipDesc}</Typography>
                </Fragment>
              }
              arrow
              placement='top'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#edf5f4',
                    color: '#000',
                    borderRadius: '8px',
                    border: '2px solid black',
                    padding: '10px',
                  },
                },
                arrow: {
                  sx: {
                    color: '#edf5f4',
                    '&::before': {
                      border: '2px solid black', // Adding the border to the arrow
                    },
                  },
                },
              }}>
              <Box sx={{ ml: 2 }}>
                {toggleStatusLoading ? (
                  <CircularProgress size={20} sx={{ ml: 5, mt: 1 }} />
                ) : (
                  <ToggleButtonGroup
                    disabled={toggleStatusLoading}
                    size='small'
                    orientation='horizontal'
                    value={clientInfo?.activeStatus || null}
                    color={clientInfo?.activeStatus === clientActiveStatus.ACTIVE ? 'success' : 'error'}
                    exclusive
                    sx={{ height: 30 }}
                    onChange={(event: React.MouseEvent<HTMLElement>, nextView: string) => {
                      if (nextView) {
                        handleToggleStatus(nextView);
                      }
                    }}>
                    <ToggleButton value={clientActiveStatus.ACTIVE} aria-label={clientActiveStatus.ACTIVE}>
                      <Typography textTransform={'capitalize'}>Active</Typography>
                    </ToggleButton>
                    <ToggleButton value={clientActiveStatus.INACTIVE} aria-label={clientActiveStatus.INACTIVE}>
                      <Typography textTransform={'capitalize'}>Inactive</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Box>
            </Tooltip>
          </ListItem>
        </Grid>

        <Grid item>
          <List disablePadding sx={styles.listStyle}>
            <ListItem disablePadding disableGutters sx={styles.listItemStyle}>
              <ListItemAvatar>
                <Avatar>
                  <Person4 />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={(caseManagerOfClient?.firstName || '') + ' ' + (caseManagerOfClient?.lastName || '')}
                primaryTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
                secondary='Case Manager'
                secondaryTypographyProps={{ fontWeight: 'bold' }}
              />
            </ListItem>

            <ListItem disablePadding disableGutters sx={styles.listItemStyle}>
              {/* Navigator name/button */}
              {clientNavigator ? (
                <>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonPin />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={clientNavigator?.firstName + ' ' + clientNavigator?.lastName}
                    primaryTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
                    secondary={
                      <React.Fragment>
                        <Box sx={{ display: 'flex' }}>
                          <Typography fontWeight={'bold'} sx={{ mr: 2 }}>
                            Navigator
                          </Typography>
                          {user?.role === UserRolesEnum.SUPER_ADMIN ? (
                            <Tooltip title='Update Navigator' arrow>
                              <IconButton
                                size='small'
                                color='primary'
                                onClick={() => {
                                  setShowNavigatorModal((old: boolean) => !old);
                                }}>
                                <EditOutlined />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </React.Fragment>
                    }
                  />
                </>
              ) : !isClientView && shouldShowButton('assignNavigator', user?.role) ? (
                <Button
                  variant='text'
                  size='small'
                  onClick={() => {
                    setShowNavigatorModal((old: boolean) => !old);
                  }}>
                  <Typography variant='body1' textTransform={'none'} color={COLORS.theme.linkColor}>
                    Assign navigator
                  </Typography>
                </Button>
              ) : null}
            </ListItem>
            {!isClientView && shouldShowButton('suspendClient', user?.role) ? (
              <>
                {/* Suspend Client Button */}
                <ListItem disablePadding disableGutters sx={{ width: 'fit-content' }}>
                  <LoadingButton
                    onClick={() => {
                      setSuspendModal(true);
                    }}
                    color='error'
                    label={isSuspended ? 'Suspended' : 'Suspend Client'}
                    styles={{ textTransform: 'none' }}
                    disabled={isSuspended}
                    size='medium'
                    type='submit'
                    variant='contained'
                    startIcon={<BlockRounded />}
                  />
                </ListItem>
              </>
            ) : null}
            {shouldShowButton('suspendClient', user?.role) ? (
              <ListItem disablePadding disableGutters sx={{ width: 'fit-content', ml: 1 }}>
                <LoadingButton
                  onClick={() => {
                    setApprovalAlert(true);
                  }}
                  color='info'
                  label='Convert Prospective'
                  loading={convertProspectiveLoading}
                  styles={{ textTransform: 'none' }}
                  disabled={false}
                  size='medium'
                  type='submit'
                  variant='contained'
                  startIcon={<AutorenewIcon />}
                />
              </ListItem>
            ) : null}
          </List>
        </Grid>
      </Grid>

      <SuspendReasonModal
        open={suspendModal}
        onClose={() => setSuspendModal(false)}
        clientId={clientInfo.id}
        onSubmit={() => setIsSuspended(true)}
      />
      <GenericDialog
        onClose={() => setApprovalAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Convert to Prospective'
        disagreeBtnText='Cancel'
        color='success'
        agreeFunc={() => {
          convertToProspective(clientInfo.id);
        }}
        open={approvalAlert}
      />
    </>
  );
};

const styles = {
  customChipLabel: {
    fontSize: '10px',
    textTransform: 'uppercase',
    lineHeight: 1.5,
  },
  centerGrid: {
    justifyContent: {
      lg: 'space-between',
      md: 'center',
    },
  },
  listStyle: {
    display: {
      lg: 'flex',
      md: 'flex',
    },
  },
  listItemStyle: {
    width: 'fit-content',
    pr: {
      lg: 4,
      md: 4,
    },
  },
};
