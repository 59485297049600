import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getLastSyncDate, syncCSVClientData, syncData } from '../../../services/syncData.service';
import { toast } from 'react-toastify';
import { LoadingButton } from '../../../components/LoadingButton/LoadingButton';
import { PersonOutline, Sync } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DashboardSmallCard } from '../../../components/DashboardSmallCard/DashboardSmallCard';

const Setting = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<any>(false);
  // const [remainingLoading, setRemainingloading] = React.useState<any>(false);
  const [isFileUploaded, setIsFileUploaded] = React.useState<any>(false);
  const [lastSyncDate, setLastSyncDate] = React.useState<any>();

  React.useEffect(() => {
    (async () => {
      const result = await getLastSyncDate();
      setLastSyncDate(result);
    })();
  }, [isFileUploaded]);

  const handleClick = async () => {
    try {
      setLoading(true);
      const result: any = await syncData();
      if (result && result.success) {
        toast.success(result.message);
      } else {
        toast.error('Data Syncing Failed!');
      }
      await syncCSVClientData();
      setLoading(false);
      setIsFileUploaded(true);
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };

  // const handleInterestSyncing = async () => {
  //   try {
  //     setRemainingloading(true);
  //     await syncInterestClients();
  //     setRemainingloading(false);
  //   } catch (error: any) {
  //     console.log(error.message);
  //     setRemainingloading(false);
  //   }
  // };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2} mb={2}>
          <Typography variant='h5'>Settings</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} direction='column' justifyContent='center' alignItems='center'>
        <Grid item lg={4} xs={12}>
          <DashboardSmallCard
            label='User Management'
            desc='Add or manage your staff members'
            disableRipple={true}
            avatar={<PersonOutline />}
            action={
              <Box mt={2} px={2}>
                <Button onClick={() => navigate('/admin/user')}>
                  <Typography variant='button'>Manage</Typography>
                </Button>
              </Box>
            }
          />
        </Grid>
        <Grid item lg={4} xs={12} display={process.env.REACT_APP_PROJECT_ID !== 'nyatlas-test' ? 'block' : 'none'}>
          <DashboardSmallCard
            label='Sync Data'
            desc='Sync client files with sftp server'
            disableRipple={true}
            avatar={<Sync />}
            action={
              <Box mt={2} px={4.5}>
                <LoadingButton variant='text' onClick={handleClick} loading={loading} label='Sync' />
              </Box>
            }
          />

          {/* Last Synced Date */}
          <Box>
            <Typography textAlign={'right'} fontSize={12} fontWeight={'500'} fontStyle={'italic'}>
              Last synced on: As of{' '}
              {lastSyncDate ? dayjs(lastSyncDate?.lastSyncAt.toDate()).format('M/D/YYYY h:mma EST') : '---'}
            </Typography>
          </Box>
        </Grid>
        {/* <LoadingButton onClick={handleInterestSyncing} loading={remainingLoading} label='Sync Interest' /> */}
      </Grid>
    </Box>
  );
};

export default Setting;
