import { Box, Button, Grid, IconButton, InputBase, Paper, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { COLORS } from '../../utils/colors';
import { AddCircleOutlineOutlined, Search } from '@mui/icons-material';
import FilterList from './FilterList';
import { CustomDataGrid } from './CustomDataGrid';
import { CustomNoRowsOverlay } from './StyledGridOverlay';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

export interface IFilterItem {
  bgColor: string;
  textColor: string;
  id: number | string;
  label: string;
  icon: any;
  tooltip?: any;
}

interface ICustomTable {
  rows: any[];
  columns: any[];
  loading: boolean;
  getRowId?: any;
  paginationModel: any;
  rowCount: any;
  onPaginationModelChange: any;
  onSortModelChange?: any;
  sortModel?: any;
  title: string;
  description?: string;
  filterList: IFilterItem[];
  onChangeSearchQuery?: any;
  primaryButtonAction?: any;
  filterButtonAction?: any;
  onFilterListChange: any;
  primaryButtonLable: string;
  secondaryButtonLable?: string;
  secondaryButtonAction?: any;
  onRowClick?: any;
  primaryButtonIcon?: any;
  secondaryButtonIcon?: any;
  disbaleMultiSelect?: boolean;
  hideSearch?: boolean;
  showFilterButton?: boolean;
  disabled?: boolean;
  disableSecondaryButton?: boolean;
  handleSearch?: any;
  searchQuery?: string;
  hidePrimaryBtn?: boolean;
  style?: any;
  searchBoxPlaceHolder?: string;
}

export default function CustomTable(props: ICustomTable) {
  const PAGE_SIZE = 10;

  const [activeIds, setActiveIds] = useState<any[]>([]);

  const handleFilterClick = (id: number) => {
    setActiveIds((prevActiveIds: any) => {
      if (props.disbaleMultiSelect) {
        props.onFilterListChange([id]);
        return [id];
      }
      if (prevActiveIds.includes(id)) {
        const list = prevActiveIds.filter((activeId: any) => activeId !== id);
        props.onFilterListChange(list);
        return list;
      } else {
        props.onFilterListChange([...prevActiveIds, id]);
        return [...prevActiveIds, id];
      }
    });
  };

  return (
    <Box mt={2} pt={5}>
      <Grid container>
        <Grid item xs={4} mb={2}>
          <Typography variant='h5'>{props.title}</Typography>
          {props.description && (
            <Typography variant='caption' textTransform={'none'}>
              {props.description}
            </Typography>
          )}
        </Grid>
        <Grid item xs={8} mb={2}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            {!props.hideSearch && (
              <Paper
                elevation={0}
                component='form'
                onSubmit={(event) => {
                  event.preventDefault();
                  if (props.handleSearch) {
                    props.handleSearch();
                  }
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '8px',
                  background: COLORS.client.searchBarBg,
                }}>
                <InputBase
                  sx={{ ml: 1, flex: 1, width: 200 }}
                  placeholder={props?.searchBoxPlaceHolder || 'Type to search'}
                  value={props.searchQuery ? props.searchQuery : ''}
                  onChange={(event) => {
                    props.onChangeSearchQuery(event.target.value);
                  }}
                />
                <IconButton
                  type='button'
                  sx={{ p: '10px', color: COLORS.theme.primaryColor }}
                  onClick={props.handleSearch ? () => props.handleSearch() : () => {}}>
                  <Search />
                </IconButton>
              </Paper>
            )}
            {props.showFilterButton ? (
              <Tooltip title='Filters' arrow>
                <Box sx={{ ml: 1, mr: 1 }}>
                  <IconButton
                    onClick={() => {
                      props.filterButtonAction();
                    }}>
                    <FilterAltOutlinedIcon color='primary' fontSize='large' />
                  </IconButton>
                </Box>
              </Tooltip>
            ) : null}
            {props.hidePrimaryBtn ? null : (
              <Button
                onClick={() => {
                  props.primaryButtonAction();
                }}
                disabled={props.disabled}
                startIcon={props.primaryButtonIcon || <AddCircleOutlineOutlined />}
                variant='contained'
                size='small'
                sx={{ textTransform: 'none' }}>
                <Typography variant='body2' noWrap>
                  {props.primaryButtonLable}
                </Typography>
              </Button>
            )}
            {props.secondaryButtonLable && (
              <Button
                onClick={() => {
                  props.secondaryButtonAction();
                }}
                disabled={props.disableSecondaryButton}
                startIcon={props.secondaryButtonAction || <AddCircleOutlineOutlined />}
                variant='contained'
                size='small'
                sx={{ textTransform: 'none' }}>
                <Typography variant='body2' noWrap>
                  {props.secondaryButtonLable}
                </Typography>
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <FilterList activeIds={activeIds} filterList={props.filterList} handleFilterClick={handleFilterClick} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CustomDataGrid
            rows={props.rows}
            columns={props.columns}
            loading={props.loading}
            onRowClick={props.onRowClick}
            getRowId={props.getRowId}
            rowCount={props.rowCount || 0}
            pageSizeOptions={[PAGE_SIZE]}
            paginationModel={props.paginationModel}
            paginationMode='server'
            onPaginationModelChange={props.onPaginationModelChange}
            checkboxSelection
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            style={props.style ? props.style : { minHeight: 750 }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            onSortModelChange={props.onSortModelChange}
            sortModel={props.sortModel}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
